import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/carbonate_disc_search.module.scss"

class CarbonateDisclosureSearch extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return (
        <div id = {Styles.carbonate_disc_search}>
        <div className={Styles.carbDisclosure}>
        By installing Carbonate, you agree to the <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a>. Carbonate is a Chromium-based browser that starts with Windows and sets up as default browser.
          </div>
        </div>
    )
  }
}
export default CarbonateDisclosureSearch
